import { Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Divider, Link, Slide, Snackbar, Tab, Typography, Tabs, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, CardHeader, Accordion, AccordionSummary, AccordionDetails, Dialog, AppBar, IconButton, Toolbar, DialogContent, DialogActions, Select, Autocomplete, Checkbox } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionProps } from '@mui/material/transitions';
import Grid from '@mui/material/Grid2';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import RecyclingIcon from '@mui/icons-material/Recycling';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditRoundedIcon from '@mui/icons-material/EditRounded';


const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



interface Aktor {
    Navn: string;
    Besoksadresse: string;
    Besokspostnummer: string;
    Besokssted: string;
    Mobilnummer: string;
    Epost: string;
    OrgNummer: string;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function InnsendingDetaljer() {
    const { aktorid } = useParams();
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingInfo, setisLoadingInfo] = useState("");
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [aktor, setAktor] = useState<Aktor | null>(null);
    const [aktorEier, setAktorEier] = useState<Aktor | null>(null);
    const [ModalPlombeBrudd, setModalPlombebrudd] = useState(false);
    const token = sessionStorage.getItem("jvtoken") || "";
    const [instrumenterGruppert, setInstrumenterGruppert] = useState<Record<string, InstrumentGruppert>>({});
    const [instrumenterGruppertKasserte, setInstrumenterGruppertKasserte] = useState<Record<string, InstrumentGruppert>>({});
    const [AktivtInstrument, setAktivtInstrument] = useState(0);
    const [AktivtInstrumentIndex, setAktivtInstrumentIndex] = useState(0);
    const [ModalAktivePlomber, setModalAktivePlomber] = useState<any[]>([]);
    const [ModalKasserInstrument, setModalKasserInstrument] = useState(false);
    const [ModalReaktiverInstrument, setModalReaktiverInstrument] = useState(false);
    const [AktivInstrumentType, setAktivInstrumentType] = useState("");
    const [instrumenter, setInstrumenter] = useState<Instrument[] | null>(null);
    const [instrumenterKasserte, setInstrumenterKasserte] = useState<Instrument[] | null>(null);
    const [instrumentTyper, setInstrumentTyper] = useState<any[]>([]);
    const [instrumentDelKategorier, setInstrumentDelKategorier] = useState<any[]>([]);
    const nyInstrumentTypeRef = useRef<any>([]);
    const [Fabrikater, setFabrikater] = useState<any[]>([]);
    const nyFabrikatRef = useRef<any>([]);
    const [Modeller, setModeller] = useState<any[]>([]);
    const nyModellRef = useRef<any>([]);
    const [ModalEndreInstrument, setModalEndreInstrument] = useState(false);
    const [ModalEndrePlomber, setModalEndrePlomber] = useState(false);
    const nyPlombeNavnRef = useRef<any>([]);
    const nyPlombeTypeRef = useRef<any>([]);
    const nyPlombeNummerRef = useRef<any>([]);
    const nyPlombePlasseringRef = useRef<any>([]);
    const [NyePlomber, setNyePlomber] = useState<any[]>([]);
    const [plombeSlett, setPlombeSlett] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [ModalInstrumentDelNy, setModalInstrumentDelNy] = useState(false);
    const [ModalInstrumentDelSlett, setModalInstrumentDelSlett] = useState(false);
    const [InstrumentMerking, setInstrumentMerking] = useState("");
    const [AktivInstrumentDelId, setAktivInstrumentDelId] = useState(0);
    const [AktivInstrumentDelIndex, setAktivInstrumentDelIndex] = useState(0);
    const [Benevninger, setBenevninger] = useState<any[]>([]);


    interface Instrument {
        InstrumentId: number;
        Merking?: string;
        Fabrikat?: string;
        Modell?: string;
        InstrumentType: string;
        Serienummer?: string;
        Strekkode?: string;
        InstallasjonsDato?: string;
        SisteMeldingDato?: string;
        TypeprovingssertifikatNr?: string;
        MaksKapasitet?: string;
        Plombenavn?: string;
        Plombetype?: string;
        Plombenummer?: string;
        Plombeplassering?: string;
        Status?: string;
        InstrumentTypeId: number;
        Instrumentdeltype?: string;
        Kategori?: string;
        Delinger?: string;
        instrumentdeler?: Instrumentdel[];
        plomber?: Plombe[];
        FlereInstrumentDeler?: boolean;
    }

    interface Instrumentdel {
        InstrumentdelType: string;
        Kategori: string;
        MaksKapasitet: string;
        Delinger: string;
        Serienummer: string;
        Merking: string;
        InstrumentdelKategori: string;
        Kapasitet: string;
        KapasitetBenevning: string;
        Deling: string;
        DelingBenevning: string;
        InstrumentdelId: number;
    }

    interface Plombe {
        PlomberingId: number;
        PlombenavnId: number;
        PlomberingNavn: string;
        Plombenummer: number;
        Plassering: string;
        PlombetypeId: number;
        PlomberingType: string;
    }

    interface InstrumentGruppert {
        InstrumentType: string;
        Instruments: Instrument[];
    }


    const handleChangePanel = (panel: string) => (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnackError(false);
    }


    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const ModalEndreInstrumentLukk = () => {
        EndreInstrumentFormik.resetForm();
        setModalEndreInstrument(false);
    };

    const ModalInstrumentDelNyAapne = (instrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setModalInstrumentDelNy(true);
        setAktivtInstrument(instrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
    };

    const ModalInstrumentDelNyLukk = () => {
        NyInstrumentDelFormik.resetForm();
        setModalInstrumentDelNy(false);
    };

    const ModalEndreInstrumentAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        EndreInstrumentFormik.setValues({ ...EndreInstrumentFormik.values, merking: instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.Merking || "" });
        setModalEndreInstrument(true);
    };

    const ModalEndrePlomberLukk = () => {
        setModalEndrePlomber(false);
        setNyePlomber([]);
        setPlombeSlett([]);
    };

    const ModalEndrePlomberAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalAktivePlomber(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.plomber || []);
        setModalEndrePlomber(true);
    };

    const LeggtilNyPlombe = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var nypltypet = nyPlombeTypeRef.current.innerText;
        var nypltypei = nypltypet.toString().split("\n");
        var nypltype = nypltypei[1];
        var nyplnavnt = nyPlombeNavnRef.current.innerText;
        var nyplnavni = nyplnavnt.toString().split("\n");
        var nyplnavn = nyplnavni[1];
        var data = new FormData(event.currentTarget);
        var nyplnavnid = data.get('nyplnavn');
        var nypltypeid = data.get('nypltype');
        var nyplnummer = data.get('nyplnummer');
        var nyplplassering = data.get('nyplplassering');
        setNyePlomber([...NyePlomber, { nyplnavnid: nyplnavnid, nyplnavn: nyplnavn, nypltype: nypltype, nypltypeid: nypltypeid, nyplnummer: nyplnummer, nyplplassering: nyplplassering }]);
        event.currentTarget.reset();
    }

    const handleCheckSlettNyPlombe = (data: any) => {
        if (data.target.checked) {
            setNyePlomber(
                NyePlomber.filter((plomber: any, indexnypl: number) =>
                    indexnypl !== parseInt(data.target.value)
                )
            );
        }
    }

    const handleCheckSlettPlombe = (data: any) => {
        if (data.target.checked) {
            plombeSlett.push(data.target.value);
            const ModPlomber = ModalAktivePlomber.map(plomber => {
                if (plomber.PlomberingId === parseInt(data.target.value)) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: true,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
        } else {
            const ModPlomber = ModalAktivePlomber.map(plomber => {
                if (plomber.PlomberingId === parseInt(data.target.value)) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: false,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
            plombeSlett.splice(plombeSlett.indexOf(parseInt(data.target.value)), 1);
        }
    }


    const EndreInstrumentValidationSchema = Yup.object({
        merking: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Merking er obligatorisk'),
    });

    const EndreInstrumentFormik = useFormik({
        initialValues: {
            merking: InstrumentMerking,
        },
        validationSchema: EndreInstrumentValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Redigerer instrument...");
            setisLoading(true);
            ModalEndreInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('Merking', values.merking || "");
            await axios.post(APIURL + "/innsending/instrumentendre", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument endret");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under endring av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            EndreInstrumentFormik.resetForm();
        },
    });

    const NyInstrumentDelValidationSchema = Yup.object({
        // instrumentdeltype: Yup.string().required('Instrumentdeltype er obligatorisk'),
        // instrumentdelkategori: Yup.string().required('Kategori er obligatorisk'),
        // makskapasitet: Yup.string().required('Maks kapasitet er obligatorisk'),
        // delinger: Yup.string().required('Deling er obligatorisk'),
        // serienummer: Yup.string().required('Serienummer er obligatorisk'),
        // merking: Yup.string().required('Merking er obligatorisk'),
    });

    const NyInstrumentDelFormik = useFormik({
        initialValues: {
            instrumentdelType: "",
            instrumentdelKategori: "",
            makskapasitet: "",
            makskapasitetbenevnelse: "",
            delinger: "",
            delingermaaleenhet: "",
            serienummer: "",
            merking: "",
        },
        validationSchema: NyInstrumentDelValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Oppretter ny instrumentdel...");
            setisLoading(true);
            ModalInstrumentDelNyLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('serienummer', values.serienummer);
            instformdata.append('merking', values.merking);
            instformdata.append('kapasitet', values.makskapasitet);
            instformdata.append('delinger', values.delinger);
            instformdata.append('kapasitetBenevningId', values.makskapasitetbenevnelse);
            instformdata.append('delingBenevningId', values.delingermaaleenhet);
            instformdata.append('instrumentdelTypeId', values.instrumentdelType);
            instformdata.append('instrumentdelKategoriId', values.instrumentdelKategori);
            await axios.post(APIURL + "/innsending/instrumentdelny", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrumentdel lagt til");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under lagring av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            NyInstrumentDelFormik.resetForm();
        },
    });


    const NyttInstrumentValidationSchema = Yup.object({
        instrumenttype: Yup.string().required('Instrumenttype er obligatorisk'),
        installasjonsdato: Yup.string().required('Installasjonsdato er obligatorisk'),
        fabrikat: Yup.string().required('Fabrikat er obligatorisk'),
        modell: Yup.string().required('Modell er obligatorisk'),
    });

    const NyttInstrumentFormik = useFormik({
        initialValues: {
            instrumenttype: "",
            installasjonsdato: moment().format('YYYY-MM-DD'),
            fabrikat: "",
            modell: "",
        },
        validationSchema: NyttInstrumentValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Oppretter nytt instrument...");
            setisLoading(true);
            ModalReaktiverInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentType', values.instrumenttype);
            instformdata.append('Fabrikat', values.fabrikat);
            instformdata.append('Modell', values.modell);
            instformdata.append('Installasjonsdato', values.installasjonsdato);
            await axios.post(APIURL + "/innsending/instrumentny", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument lagt til");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under lagring av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            NyttInstrumentFormik.resetForm();
        },
    });

    const ReaktiveringValidationSchema = Yup.object({
        reaktiveringsaarsak: Yup.string().required('Reaktiveringsårsak er obligatorisk').min(3, 'Må ha minimum 10 tegn').matches(/^[a-zA-ZæøåÆØÅ0-9@!,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_!')
    });

    const ReaktiveringFormik = useFormik({
        initialValues: {
            reaktiveringsaarsak: "",
        },
        validationSchema: ReaktiveringValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Reaktiverer instrument...");
            setisLoading(true);
            ModalReaktiverInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('ReaktiveringsAarsak', values.reaktiveringsaarsak);
            await axios.post(APIURL + "/innsending/instrumentreaktivering", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument reaktivert");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under reaktivering av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            ReaktiveringFormik.resetForm();
        },
    });

    const KasseringValidationSchema = Yup.object({
        kasseringsaarsak: Yup.string().required('Kasseringsårsak er obligatorisk').min(3, 'Må ha minimum 10 tegn').matches(/^[a-zA-ZæøåÆØÅ0-9@!,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_!')
    });

    const KasseringFormik = useFormik({
        initialValues: {
            kasseringsaarsak: "",
        },
        validationSchema: KasseringValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Kasserer instrument...");
            setisLoading(true);
            ModalKasserInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('KasseringAarsak', values.kasseringsaarsak);
            await axios.post(APIURL + "/innsending/instrumentkassering", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument kassert");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under kassering av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            KasseringFormik.resetForm();
        },
    });

    const sendEndrePlomber = async () => {
        setisLoadingInfo("Endrer plomber...");
        setisLoading(true);
        var formdata = {
            nyeplomber: NyePlomber,
            plombeslett: plombeSlett,
        };
        await axios.post(APIURL + "/innsending/instrumentplomber/" + AktivtInstrument, formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor();
                    setSnackMelding("Plomber endret");
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under innsending av endring av plomber. " + response?.data?.message);
                    setOpenSnackError(true);
                }
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding("Feil under innsending av endring av plomber. " + e?.response?.data?.message);
                setOpenSnackError(true);
            });
        getaktor();
        ModalEndrePlomberLukk();
        setisLoading(false);
    }

    const sendSlettInstrumentDel = async () => {
        setisLoadingInfo("Sletter instrumentdel...");
        setisLoading(true);
        var formdata = {
            instrumentdelId: AktivInstrumentDelId,
        };
        await axios.post(APIURL + "/innsending/instrumentdelslett/" + AktivInstrumentDelId, formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor();
                    setSnackMelding("Instrumentdel slettet");
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under sletting av instrumentdel. " + response?.data?.message);
                    setOpenSnackError(true);
                }
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding("Feil under sletting av instrumentdel. " + e?.response?.data?.message);
                setOpenSnackError(true);
            });
        getaktor();
        ModalInstrumentDelSlettLukk();
        setisLoading(false);
    }

    function getinstrumenttyper() {
        axios.get(APIURL + "/innsending/instrumenttyper", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstrumentTyper(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getbenevninger() {
        axios.get(APIURL + "/innsending/benevninger", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setBenevninger(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getfabrikater() {
        axios.get(APIURL + "/innsending/instrumentfabrikater", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setFabrikater(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }


    function getinstrumentdelkategorier() {
        axios.get(APIURL + "/innsending/instrumentdelkategorier", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstrumentDelKategorier(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getmodeller(fabrikatId: number) {
        axios.get(APIURL + "/innsending/instrumentmodeller/" + fabrikatId, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setModeller(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getaktor() {
        axios.get(APIURL + "/innsending/lokasjon/" + aktorid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setAktor(response.data);
                    setAktorEier(response.data.eier);
                    setAktivAktorId(response?.data?.AktorId);
                    setInstrumenter(response.data?.instrumenter);
                    setInstrumenterKasserte(response.data?.instrumenterKasserte);

                    const instrumenterGruppert = response.data?.instrumenter?.reduce((acc: Record<number, InstrumentGruppert>, instrument: Instrument) => {
                        if (!acc[instrument.InstrumentTypeId]) {
                            acc[instrument.InstrumentTypeId] = {
                                InstrumentType: instrument.InstrumentType,
                                Instruments: []
                            };
                        }

                        acc[instrument.InstrumentTypeId].Instruments.push(instrument);

                        return acc;
                    }, {} as Record<number, InstrumentGruppert>);

                    setInstrumenterGruppert(instrumenterGruppert);

                    const instrumenterGruppertKasserte = response.data?.instrumenterKasserte?.reduce((acc: Record<number, InstrumentGruppert>, instrument: Instrument) => {
                        if (!acc[instrument.InstrumentTypeId]) {
                            acc[instrument.InstrumentTypeId] = {
                                InstrumentType: instrument.InstrumentType,
                                Instruments: []
                            };
                        }
                        acc[instrument.InstrumentTypeId].Instruments.push(instrument);
                        return acc;
                    }, {} as Record<number, InstrumentGruppert>);

                    setInstrumenterGruppertKasserte(instrumenterGruppertKasserte);

                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }


    const ModalInstrumentDelSlettAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number, AktivInstrumentDelIndex: number, instrumentdelId: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setAktivInstrumentDelIndex(AktivInstrumentDelIndex);
        setAktivInstrumentDelId(instrumentdelId);
        setModalInstrumentDelSlett(true);
    };

    const ModalInstrumentDelSlettLukk = () => {
        setAktivInstrumentDelId(0);
        setModalInstrumentDelSlett(false);
    };


    const ModalPlombebruddLukk = () => {
        setModalPlombebrudd(false);
    };

    const ModalPlombebruddAapne = (ValgtInstrumentId: number, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setModalPlombebrudd(true);
    };

    const ModalKasserInstrumentLukk = () => {
        KasseringFormik.resetForm();
        setModalKasserInstrument(false);
    };
    const ModalKasserInstrumentAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalKasserInstrument(true);
    };

    const ModalReaktiverInstrumentLukk = () => {
        ReaktiveringFormik.resetForm();
        setModalReaktiverInstrument(false);
    };

    const ModalReaktiverInstrumentAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        console.log(ValgtInstrumentId, instrumentType, InstrumentIndex);
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalReaktiverInstrument(true);
    };

    useEffect(() => {
        getaktor();
        if (instrumentTyper?.length === 0) {
            getinstrumenttyper();
        }
        if (Fabrikater?.length === 0) {
            getfabrikater();
        }
        if (instrumentDelKategorier?.length === 0) {
            getinstrumentdelkategorier();
        }
        if (Benevninger?.length === 0) {
            getbenevninger();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }}>
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>



                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalInstrumentDelSlett}
                            onClose={ModalInstrumentDelSlettLukk}
                            TransitionComponent={Transition}
                        >
                            <Box component="form" id="instrumentdelslettform" onSubmit={sendSlettInstrumentDel}>
                                <AppBar sx={{
                                    position: 'relative',
                                    width: '100%',
                                    margin: 0,
                                }}>
                                    <Toolbar
                                        sx={{
                                            backgroundColor: "#f44336"
                                        }}
                                    >
                                        <Typography sx={{
                                            ml: 2,
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                            variant="h6"
                                            component="div">
                                            <DeleteForeverIcon /> {Text({ tid: "bekreftslettinstrumentdel" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalInstrumentDelSlettLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>

                                <DialogContent>
                                    {instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.length && instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.length < 2 ?
                                        <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" sx={{ mb: 2 }}>
                                            Du kan ikke slette instrumentdelen, da dette instrumentet kun har en instrumentdel. Legg til en ny instrumentdel først dersom du ønsker at denne instrumentdelen skal slettes.
                                        </Alert>
                                        :
                                        <>
                                            <Typography variant="body1" color="text.primary" sx={{ mb: 3 }}>
                                                Er du sikker på at du vil slette instrumentdel merket <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.[AktivInstrumentDelIndex]?.Merking ?? ""}</b> med serienummer <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.[AktivInstrumentDelIndex]?.Serienummer ?? ""}</b>?
                                            </Typography>

                                            <Typography variant="body1" color="text.primary">
                                                Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                            </Typography>
                                            <Typography variant="body1" color="text.primary">
                                                Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                            </Typography>
                                            <Typography variant="body1" color="text.primary">
                                                Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                            </Typography>
                                        </>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="instrumentdelslettform"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <DeleteForeverIcon className='jvbtnicon' />
                                        {Text({ tid: "slettinstrumentdel" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalInstrumentDelSlettLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalKasserInstrument}
                            onClose={ModalKasserInstrumentLukk}
                            TransitionComponent={Transition}
                        >
                            <Box component="form" id="kasserinstrumentform" onSubmit={KasseringFormik.handleSubmit}>
                                <AppBar sx={{
                                    position: 'relative',
                                    width: '100%',
                                    margin: 0,
                                }}>
                                    <Toolbar
                                        sx={{
                                            backgroundColor: "#f44336"
                                        }}
                                    >
                                        <Typography sx={{
                                            ml: 2,
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                            variant="h6"
                                            component="div">
                                            <DeleteForeverIcon /> {Text({ tid: "kasserinstrument" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalKasserInstrumentLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>

                                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" sx={{ mb: 2 }}>
                                        Bekreft kassering av instrument. Se over at informasjonen under stemmer med instrumentet du skal kassere, og oppgi årsak.
                                    </Alert>

                                    <Typography variant="body1" color="text.primary">
                                        Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Installasjonsdato: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.InstallasjonsDato}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Maks kapasitet: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.MaksKapasitet}</b>
                                    </Typography>
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="kasseringsaarsak"
                                            label={Text({ tid: "kasseringsaarsak" })}
                                            name="kasseringsaarsak"
                                            defaultValue={KasseringFormik.values.kasseringsaarsak}
                                            onChange={KasseringFormik.handleChange}
                                            onBlur={KasseringFormik.handleBlur}
                                            error={KasseringFormik.touched.kasseringsaarsak && Boolean(KasseringFormik.errors.kasseringsaarsak)}
                                            helperText={KasseringFormik.touched.kasseringsaarsak && KasseringFormik.errors.kasseringsaarsak}
                                            required
                                        />
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="kasserinstrumentform"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <DeleteForeverIcon className='jvbtnicon' />
                                        {Text({ tid: "kasserinstrument" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalKasserInstrumentLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalReaktiverInstrument}
                            onClose={ModalReaktiverInstrumentLukk}
                            TransitionComponent={Transition}
                        >
                            <Box component="form" id="reaktiverinstrumentform" onSubmit={ReaktiveringFormik.handleSubmit}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <Typography sx={{
                                            ml: 2,
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                            variant="h6"
                                            component="div">
                                            <RecyclingIcon className='jvbtnicon' /> {Text({ tid: "reaktiverinstrument" })}

                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalReaktiverInstrumentLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>

                                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" sx={{ mb: 2 }}>
                                        Bekreft reaktivering av instrument. Se over at informasjonen under stemmer med instrumentet du skal reaktivere, og oppgi årsak.
                                    </Alert>

                                    <Typography variant="body1" color="text.primary">
                                        Merking: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Merking}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Fabrikat: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Modell: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Serienummer: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Installasjonsdato: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.InstallasjonsDato}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Maks kapasitet: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.MaksKapasitet}</b>
                                    </Typography>

                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="reaktiveringsaarsak"
                                            label={Text({ tid: "reaktiveringsaarsak" })}
                                            name="reaktiveringsaarsak"
                                            defaultValue={ReaktiveringFormik.values.reaktiveringsaarsak}
                                            onChange={ReaktiveringFormik.handleChange}
                                            onBlur={ReaktiveringFormik.handleBlur}
                                            error={ReaktiveringFormik.touched.reaktiveringsaarsak && Boolean(ReaktiveringFormik.errors.reaktiveringsaarsak)}
                                            helperText={ReaktiveringFormik.touched.reaktiveringsaarsak && ReaktiveringFormik.errors.reaktiveringsaarsak}
                                            required
                                        />
                                    </Grid>

                                    <Box display="flex" alignItems="center">
                                        <Checkbox required />
                                        <Typography variant="body1" color="text.primary">
                                            Måleredskapet er samsvarsvurdert og har gyldig samsvarsvurdering.
                                        </Typography>
                                    </Box>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="reaktiverinstrumentform"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <RecyclingIcon className='jvbtnicon' />
                                        {Text({ tid: "reaktiverinstrument" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalReaktiverInstrumentLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalEndreInstrument}
                            onClose={ModalEndreInstrumentLukk}
                            TransitionComponent={Transition}
                        >
                            <Box component="form" id="endreinstrumentform" onSubmit={EndreInstrumentFormik.handleSubmit}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                                            <EditIcon className='jvbtnicon' /> {Text({ tid: "endreinstrument" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalEndreInstrumentLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>
                                    <Grid size={{ xs: 12 }}>

                                        <Typography variant="body1" color="text.primary">
                                            Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.primary">
                                            Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.primary" gutterBottom>
                                            Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                        </Typography>

                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="merking"
                                            label={Text({ tid: "merking" })}
                                            name="merking"
                                            defaultValue={EndreInstrumentFormik.values.merking}
                                            onChange={EndreInstrumentFormik.handleChange}
                                            onBlur={EndreInstrumentFormik.handleBlur}
                                            error={EndreInstrumentFormik.touched.merking && Boolean(EndreInstrumentFormik.errors.merking)}
                                            helperText={EndreInstrumentFormik.touched.merking && EndreInstrumentFormik.errors.merking}
                                            required
                                        />
                                        <Typography variant="caption" color="text.secondary">
                                            Med "merking" menes "Internt instrumentnavn".
                                        </Typography>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="endreinstrumentform"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalEndreInstrumentLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalEndrePlomber}
                            onClose={ModalEndrePlomberLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "plomber" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalEndrePlomberLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                return <TableRow key={"plrow" + PlomberingId} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                    <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                    <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                    <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                    <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                    <TableCell className='jvtablerow'>
                                                        <FormControlLabel
                                                            key={"plid_" + PlomberingId}
                                                            value={PlomberingId}
                                                            control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                            label=""
                                                            onChange={handleCheckSlettPlombe}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            })
                                            }
                                            {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                    <TableCell className='jvtablerow'>
                                                        {nyplnavn}
                                                    </TableCell>
                                                    <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                    <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                    <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                    <TableCell className='jvtablerow'>
                                                        <FormControlLabel
                                                            key={"nyplid_" + indexnypl}
                                                            value={indexnypl}
                                                            control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                            checked={false}
                                                            label=""
                                                            onChange={handleCheckSlettNyPlombe}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider sx={{ mb: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    Legg til ny plombe
                                </Typography>
                                <Box component="form" id="nyplomberform" onSubmit={LeggtilNyPlombe}>
                                    <Grid container>
                                        <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Plombenavn"
                                                name="nyplnavn"
                                                id="nyplnavn"
                                                defaultValue={"1"}
                                                ref={nyPlombeNavnRef}
                                                required
                                            >
                                                <MenuItem value="1">
                                                    {Text({ tid: "taksameter" })}
                                                </MenuItem>
                                                <MenuItem value="2">
                                                    {Text({ tid: "spenning" })}
                                                </MenuItem>
                                                <MenuItem value="3">
                                                    {Text({ tid: "koblingsboks" })}
                                                </MenuItem>
                                                <MenuItem value="4">
                                                    {Text({ tid: "display" })}
                                                </MenuItem>
                                                <MenuItem value="5">
                                                    {Text({ tid: "kalibreringskode" })}
                                                </MenuItem>
                                                <MenuItem value="6">
                                                    {Text({ tid: "indikator" })}
                                                </MenuItem>
                                                <MenuItem value="7">
                                                    {Text({ tid: "veiecelle" })}
                                                </MenuItem>
                                                <MenuItem value="8">
                                                    {Text({ tid: "annet" })}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Type"
                                                name="nypltype"
                                                id="nypltype"
                                                defaultValue={"2"}
                                                ref={nyPlombeTypeRef}
                                                required
                                            >
                                                <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                    {Text({ tid: "elektronisk" })}
                                                </MenuItem>
                                                <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                    {Text({ tid: "fysisk" })}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="nyplnummer"
                                                label={Text({ tid: "plombenummer" })}
                                                name="nyplnummer"
                                                required
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 3 }} sx={{ mb: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="nyplplassering"
                                                label={Text({ tid: "plassering" })}
                                                name="nyplplassering"
                                                required
                                            />
                                        </Grid>
                                        <Grid sx={{ ml: 1, pt: 2 }}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                form="nyplomberform"
                                            >
                                                <AddCircleIcon className='jvbtnicon' />
                                                {Text({ tid: "leggtil" })}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    onClick={sendEndrePlomber}
                                    variant="outlined"
                                    color="success"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    Endre plomber
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={ModalEndrePlomberLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>



                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={ModalInstrumentDelNy}
                            onClose={ModalInstrumentDelNyLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "leggtilnyinstrumentdel" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalInstrumentDelNyLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Typography variant="h6" gutterBottom>
                                    Legg til ny instrumentdel
                                </Typography>

                                <Box component="form" id="nyinstrumentdelform" onSubmit={NyInstrumentDelFormik.handleSubmit}>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "instrumentdeltype" })}
                                                name="instrumentdelType"
                                                id="instrumentdelType"
                                                value={NyInstrumentDelFormik.values.instrumentdelType || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.instrumentdelType &&
                                                    Boolean(NyInstrumentDelFormik.errors.instrumentdelType)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.instrumentdelType &&
                                                    NyInstrumentDelFormik.errors.instrumentdelType
                                                }
                                            >
                                                <MenuItem value="1">{Text({ tid: "maalekammer" })}</MenuItem>
                                                <MenuItem value="2">{Text({ tid: "malefunksjon" })}</MenuItem>
                                                <MenuItem value="3">{Text({ tid: "generell" })}</MenuItem>
                                                <MenuItem value="4">{Text({ tid: "lengde" })}</MenuItem>
                                                <MenuItem value="5">{Text({ tid: "temperatur" })}</MenuItem>
                                                <MenuItem value="6">{Text({ tid: "loddiloddparti" })}</MenuItem>
                                            </TextField>

                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>

                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "Kategori/Klasse" })}
                                                name="instrumentdelKategori"
                                                id="instrumentdelKategori"
                                                value={NyInstrumentDelFormik.values.instrumentdelKategori || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.instrumentdelKategori &&
                                                    Boolean(NyInstrumentDelFormik.errors.instrumentdelKategori)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.instrumentdelKategori &&
                                                    NyInstrumentDelFormik.errors.instrumentdelKategori
                                                }
                                            >
                                                {(instrumentDelKategorier || []).map((kategori: any) => (
                                                    <MenuItem key={kategori.InstrumentDelKategoriId} value={kategori.InstrumentDelKategoriId}>
                                                        {kategori.Beskrivelse}
                                                    </MenuItem>
                                                ))}
                                            </TextField>


                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="merking"
                                                label={Text({ tid: "merking" })}
                                                name="merking"
                                                defaultValue={NyInstrumentDelFormik.values.merking}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.merking && Boolean(NyInstrumentDelFormik.errors.merking)}
                                                helperText={NyInstrumentDelFormik.touched.merking && NyInstrumentDelFormik.errors.merking}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="serienummer"
                                                label={Text({ tid: "serienummer" })}
                                                name="serienummer"
                                                defaultValue={NyInstrumentDelFormik.values.serienummer}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.serienummer && Boolean(NyInstrumentDelFormik.errors.serienummer)}
                                                helperText={NyInstrumentDelFormik.touched.serienummer && NyInstrumentDelFormik.errors.serienummer}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="makskapasitet"
                                                label={Text({ tid: "makskapasitet" })}
                                                name="makskapasitet"
                                                defaultValue={NyInstrumentDelFormik.values.makskapasitet}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.makskapasitet && Boolean(NyInstrumentDelFormik.errors.makskapasitet)}
                                                helperText={NyInstrumentDelFormik.touched.makskapasitet && NyInstrumentDelFormik.errors.makskapasitet}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "makskapasitetbenevnelse" })}
                                                name="makskapasitetbenevnelse"
                                                id="makskapasitetbenevnelse"
                                                value={NyInstrumentDelFormik.values.makskapasitetbenevnelse || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.makskapasitetbenevnelse &&
                                                    Boolean(NyInstrumentDelFormik.errors.makskapasitetbenevnelse)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.makskapasitetbenevnelse &&
                                                    NyInstrumentDelFormik.errors.makskapasitetbenevnelse
                                                }
                                            >
                                                {(Benevninger || []).map((benevning: any, index: number) => (
                                                    <MenuItem key={benevning.BenevningId} value={benevning.BenevningId}>
                                                        {benevning.Kode}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="delinger"
                                                label={Text({ tid: "delinger" })}
                                                name="delinger"
                                                defaultValue={NyInstrumentDelFormik.values.delinger}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.delinger && Boolean(NyInstrumentDelFormik.errors.delinger)}
                                                helperText={NyInstrumentDelFormik.touched.delinger && NyInstrumentDelFormik.errors.delinger}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "delingermaaleenhet" })}
                                                name="delingermaaleenhet"
                                                id="delingermaaleenhet"
                                                value={NyInstrumentDelFormik.values.delingermaaleenhet || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.delingermaaleenhet &&
                                                    Boolean(NyInstrumentDelFormik.errors.delingermaaleenhet)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.delingermaaleenhet &&
                                                    NyInstrumentDelFormik.errors.delingermaaleenhet
                                                }
                                            >
                                                {(Benevninger || []).map((benevning: any, index: number) => (
                                                    <MenuItem key={benevning.BenevningId} value={benevning.BenevningId}>
                                                        {benevning.Kode}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    color="success"
                                    form="nyinstrumentdelform"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    Legg til ny instrumentdel
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={ModalInstrumentDelNyLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/innsending"
                            >
                                Innsending
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href={"/innsending/lokasjoner/" + aktorEier?.OrgNummer}
                            >
                                {aktorEier?.Navn}
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                {aktor?.Navn}
                            </Typography>
                        </Breadcrumbs>

                        <Grid container spacing={2} p={2}>
                            <Grid size={{ xs: 6 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
                                            <BusinessIcon sx={{ marginRight: '4px', color: "text.secondary" }} />
                                            <Typography variant="body1" color="text.secondary">
                                                Juridisk eier av instrument(ene)
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktorEier?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktorEier?.OrgNummer}
                                        </Typography>
                                        <Grid container>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktorEier?.Besoksadresse}<br />
                                                    {aktorEier?.Besokspostnummer} {aktorEier?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktorEier?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktorEier?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid size={{ xs: 6 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
                                            <PlaceIcon sx={{ marginRight: '4px', color: "text.secondary" }} />
                                            <Typography variant="body1" color="text.secondary">
                                                Lokasjon til instrument(ene) (underenhet)
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktor?.OrgNummer}
                                        </Typography>
                                        <Grid container>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor?.Besoksadresse}<br />
                                                    {aktor?.Besokspostnummer} {aktor?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" /* onClick={ModalEndreAktorAapne} */ size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid size={{ xs: 12 }}>
                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<CheckIcon color="success" />} label={"Aktive instrumenter (" + instrumenter?.length + ")"} iconPosition="start" />
                                    <Tab icon={<DeleteIcon color="error" />} label={"Kasserte instrumenter (" + instrumenterKasserte?.length + ")"} iconPosition="start" />
                                    <Tab icon={<AddIcon color="info" />} label={"Legg til nytt instrument"} iconPosition="start" />
                                </Tabs>

                                {/* Aktive instrumenter tab */}
                                <TabPanel value={valueTab} index={0}>
                                    <div>
                                        {Object.keys(instrumenterGruppert).map((key) => {
                                            const instrumentType = instrumenterGruppert[key];
                                            return (
                                                <div key={key}>
                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 1, mt: 1 }}>
                                                        {instrumentType.InstrumentType}
                                                    </Typography>
                                                    {instrumentType.Instruments.map((instrument, InstrumentIndex) => (
                                                        <>
                                                            <Accordion
                                                                expanded={expanded === instrument.InstrumentId.toString()}
                                                                onChange={handleChangePanel(instrument.InstrumentId.toString())}                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                    sx={{ backgroundColor: '#eeeeee' }}
                                                                >
                                                                    <DoubleArrowIcon sx={{ color: 'green', mr: 1 }} /> {instrument.Merking} - [{instrument.Fabrikat} / {instrument.Modell}] - Serienummer: {instrument.Serienummer} - JV referanse: {instrument.InstrumentId}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Box key={instrument.Serienummer}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid size={{ xs: 12 }}>
                                                                                <Box display="inline-flex" alignItems="center" sx={{ color: '#2a3eb1' }}>
                                                                                    <CalendarMonthIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                        {instrument.InstallasjonsDato ? moment(instrument.InstallasjonsDato).format('DD.MM.YYYY') : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Installasjonsdato mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                    <FingerprintIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                        {instrument.Serienummer ? instrument.Serienummer : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Serienummer mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                    <QrCodeIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary">
                                                                                        {instrument.Strekkode ? instrument.Strekkode : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Strekkode mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>

                                                                            {instrument.FlereInstrumentDeler ? (
                                                                                <Grid size={{ xs: 12 }}>

                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                        <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                            {Text({ tid: "instrumentdeler" })}
                                                                                            <IconButton
                                                                                                onClick={() => ModalInstrumentDelNyAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    ml: 1,
                                                                                                    color: "white",
                                                                                                    bgcolor: "primary.main",
                                                                                                    "&:hover": { bgcolor: "primary.dark" },
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            >
                                                                                                <AddIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Typography>
                                                                                    </Box>

                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                                <TableRow>
                                                                                                    <TableCell sx={{ color: 'white' }}>Merking</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Serienummer</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Instrumentdeltype</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Kategori/Klasse</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Kapasitet</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Delinger</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {instrument.instrumentdeler && instrument.instrumentdeler.map((instrumentdel, indexdel) => (
                                                                                                    <TableRow key={instrumentdel.Serienummer}>
                                                                                                        <TableCell>{instrumentdel.Merking}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Serienummer}</TableCell>
                                                                                                        <TableCell>{instrumentdel.InstrumentdelType}</TableCell>
                                                                                                        <TableCell>{instrumentdel.InstrumentdelKategori}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Kapasitet} {instrumentdel.KapasitetBenevning}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Deling} {instrumentdel.DelingBenevning}</TableCell>
                                                                                                        <TableCell align="right">
                                                                                                            <Button variant="contained" color="primary" size="small" sx={{ mr: 1, minWidth: '32px' }}>
                                                                                                                <EditIcon />
                                                                                                            </Button>
                                                                                                            <IconButton
                                                                                                                onClick={() => ModalInstrumentDelSlettAapne(instrument.InstrumentId, key, InstrumentIndex, indexdel, instrumentdel.InstrumentdelId)}
                                                                                                                size="small"
                                                                                                                sx={{
                                                                                                                    ml: 1,
                                                                                                                    color: "white",
                                                                                                                    bgcolor: "error.main",
                                                                                                                    "&:hover": { bgcolor: "error.dark" },
                                                                                                                    borderRadius: "4px",
                                                                                                                }}
                                                                                                            >
                                                                                                                <DeleteForeverIcon fontSize="small" />
                                                                                                            </IconButton>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            ) : (
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                                <TableRow>
                                                                                                    <TableCell sx={{ color: 'white' }}>Merking</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Serienummer</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Kapasitet</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Delinger</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {instrument.instrumentdeler && instrument.instrumentdeler.map((instrumentdel) => (
                                                                                                    <TableRow key={instrumentdel.Serienummer}>
                                                                                                        <TableCell>{instrumentdel.Merking}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Serienummer}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Kapasitet} {instrumentdel.KapasitetBenevning}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Deling} {instrumentdel.DelingBenevning}</TableCell>
                                                                                                        <TableCell align="right">
                                                                                                            <Button variant="contained" color="primary" size="small" sx={{ mr: 1, minWidth: '32px' }}>
                                                                                                                <EditIcon />
                                                                                                            </Button>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            )}



                                                                            <Grid size={{ xs: 12 }}>
                                                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                    <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                        {Text({ tid: "plomber" })}
                                                                                        <IconButton
                                                                                            onClick={() => ModalEndrePlomberAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                ml: 1,
                                                                                                color: "white",
                                                                                                bgcolor: "primary.main",
                                                                                                "&:hover": { bgcolor: "primary.dark" },
                                                                                                borderRadius: "50%",
                                                                                            }}
                                                                                        >
                                                                                            <EditIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Typography>
                                                                                </Box>

                                                                                <TableContainer component={Paper}>
                                                                                    <Table>
                                                                                        <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                            <TableRow>
                                                                                                <TableCell sx={{ color: 'white' }}>Navn</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Type</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Nummer</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Plassering</TableCell>
                                                                                                <TableCell></TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        {instrument.plomber && instrument.plomber.map((plomber) => (
                                                                                            <TableRow key={plomber.PlomberingId}>
                                                                                                <TableCell>{plomber.PlomberingNavn}</TableCell>
                                                                                                <TableCell>{plomber.PlomberingType}</TableCell>
                                                                                                <TableCell>{plomber.Plombenummer}</TableCell>
                                                                                                <TableCell>{plomber.Plassering}</TableCell>
                                                                                                <TableCell align="right">
                                                                                                    <Button variant="contained" color="primary" size="small" sx={{ mr: 1, minWidth: '32px' }}>
                                                                                                        <EditIcon />
                                                                                                    </Button>
                                                                                                    <Button variant="contained" color="error" size="small" sx={{ minWidth: '32px' }}>
                                                                                                        <DeleteForeverIcon />
                                                                                                    </Button>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </Table>
                                                                                </TableContainer>

                                                                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                                                                                    <Box display="flex" alignItems="center">
                                                                                    </Box>
                                                                                    <Box display="flex" gap={1} justifyContent="flex-end">
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            size="small"
                                                                                            onClick={() => ModalEndreInstrumentAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                        >
                                                                                            <EditIcon className='jvbtnicon' /> Endre
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            size="small"
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                        >
                                                                                            <EditIcon className='jvbtnicon' /> Meld plombebrudd
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="error"
                                                                                            size="small"
                                                                                            onClick={() => ModalKasserInstrumentAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                        >
                                                                                            <DeleteForeverIcon className='jvbtnicon' /> Kasser instrument
                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion >
                                                        </>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>

                                </TabPanel>

                                {/* Kasserte instrumenter tab */}
                                <TabPanel value={valueTab} index={1}>
                                    <div>
                                        {Object.keys(instrumenterGruppertKasserte).map((key) => {
                                            const instrumentKasserteType = instrumenterGruppertKasserte[key];
                                            return (
                                                <div key={key}>
                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 1, mt: 1 }}>
                                                        {instrumentKasserteType.InstrumentType}
                                                    </Typography>
                                                    {instrumentKasserteType.Instruments.map((instrumentKasserte, InstrumentIndex) => (
                                                        <>
                                                            <Accordion
                                                                expanded={expanded === instrumentKasserte.InstrumentId.toString()}
                                                                onChange={handleChangePanel(instrumentKasserte.InstrumentId.toString())}                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                    sx={{ backgroundColor: '#eeeeee' }}
                                                                >
                                                                    <DoubleArrowIcon sx={{ color: 'red', mr: 1 }} /> {instrumentKasserte.Merking} - [{instrumentKasserte.Fabrikat} / {instrumentKasserte.Modell}] - Serienummer: {instrumentKasserte.Serienummer} - JV referanse: {instrumentKasserte.InstrumentId}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Box key={instrumentKasserte.Serienummer}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid size={{ xs: 12 }}>
                                                                                <Box display="inline-flex" alignItems="center" sx={{ color: '#2a3eb1' }}>
                                                                                    <CalendarMonthIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                        {instrumentKasserte.InstallasjonsDato ? moment(instrumentKasserte.InstallasjonsDato).format('DD.MM.YYYY') : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Installasjonsdato mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                    <FingerprintIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                        {instrumentKasserte.Serienummer ? instrumentKasserte.Serienummer : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Serienummer mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                    <QrCodeIcon sx={{ fontSize: 16, mr: 1 }} />
                                                                                    <Typography variant="body1" color="text.secondary">
                                                                                        {instrumentKasserte.Strekkode ? instrumentKasserte.Strekkode : (
                                                                                            <Typography variant="body2" color="warning">
                                                                                                (Strekkode mangler)
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid size={{ xs: 12 }}>
                                                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                                                        Instrumentdeler
                                                                                    </Typography>
                                                                                </Box>

                                                                                <TableContainer component={Paper}>
                                                                                    <Table>
                                                                                        <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                            <TableRow>
                                                                                                <TableCell sx={{ color: 'white' }}>Merking</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Serienummer</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Instrumentdeltype</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Kategori/Klasse</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Kapasitet</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Delinger</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {instrumentKasserte.instrumentdeler && instrumentKasserte.instrumentdeler.map((instrumentdel) => (
                                                                                                <TableRow key={instrumentdel.Serienummer}>
                                                                                                    <TableCell>{instrumentdel.Merking}</TableCell>
                                                                                                    <TableCell>{instrumentdel.Serienummer}</TableCell>
                                                                                                    <TableCell>{instrumentdel.InstrumentdelType}</TableCell>
                                                                                                    <TableCell>{instrumentdel.InstrumentdelKategori}</TableCell>
                                                                                                    <TableCell>{instrumentdel.Kapasitet} {instrumentdel.KapasitetBenevning}</TableCell>
                                                                                                    <TableCell>{instrumentdel.Deling} {instrumentdel.DelingBenevning}</TableCell>
                                                                                                </TableRow>
                                                                                            ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            </Grid>

                                                                            <Grid size={{ xs: 12 }}>
                                                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                                                        Plomber
                                                                                    </Typography>
                                                                                </Box>

                                                                                <TableContainer component={Paper}>
                                                                                    <Table>
                                                                                        <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                            <TableRow>
                                                                                                <TableCell sx={{ color: 'white' }}>Navn</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Type</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Nummer</TableCell>
                                                                                                <TableCell sx={{ color: 'white' }}>Plassering</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        {instrumentKasserte.plomber && instrumentKasserte.plomber.map((plomber) => (
                                                                                            <TableRow key={plomber.PlomberingId}>
                                                                                                <TableCell>{plomber.PlomberingNavn}</TableCell>
                                                                                                <TableCell>{plomber.PlomberingType}</TableCell>
                                                                                                <TableCell>{plomber.Plombenummer}</TableCell>
                                                                                                <TableCell>{plomber.Plassering}</TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </Table>
                                                                                </TableContainer>

                                                                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                                                                                    <Box display="flex" alignItems="center">
                                                                                    </Box>
                                                                                    <Box display="flex" gap={1} justifyContent="flex-end">
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="success"
                                                                                            size="small"
                                                                                            onClick={() => ModalReaktiverInstrumentAapne(instrumentKasserte.InstrumentId, key, InstrumentIndex)}
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                        >
                                                                                            <RecyclingIcon className='jvbtnicon' /> Reaktiver instrument
                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion >
                                                        </>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </TabPanel>

                                {/* Legg til nytt instrument tab */}
                                <TabPanel value={valueTab} index={2}>

                                    <Box component="form" id="nyttinstrumentform" onSubmit={NyttInstrumentFormik.handleSubmit}>
                                        <Typography sx={{ ml: 2, mb: 5, flex: 1 }} variant="h6" component="div" gutterBottom>
                                            {Text({ tid: "opprettnyttinstrument" })}
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <Grid size={{ xs: 6 }}>
                                                <TextField
                                                    size="small"
                                                    select
                                                    fullWidth
                                                    label="Instrumenttype"
                                                    name="instrumenttype"
                                                    id="instrumenttype"
                                                    ref={nyInstrumentTypeRef}
                                                    defaultValue={NyttInstrumentFormik.values.instrumenttype}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.instrumenttype && Boolean(NyttInstrumentFormik.errors.instrumenttype)}
                                                    helperText={NyttInstrumentFormik.touched.instrumenttype && NyttInstrumentFormik.errors.instrumenttype}
                                                >
                                                    {instrumentTyper.map((instrumentType) => (
                                                        <MenuItem value={instrumentType.InstrumentTypeId}>
                                                            {instrumentType.Beskrivelse}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                            <Grid size={{ xs: 6 }}>
                                                <TextField
                                                    type="date"
                                                    margin="normal"
                                                    fullWidth
                                                    id="installasjonsdato"
                                                    label="Installasjonsdato"
                                                    name="installasjonsdato"
                                                    defaultValue={NyttInstrumentFormik.values.installasjonsdato}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.installasjonsdato && Boolean(NyttInstrumentFormik.errors.installasjonsdato)}
                                                    helperText={NyttInstrumentFormik.touched.installasjonsdato && NyttInstrumentFormik.errors.installasjonsdato}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid size={{ xs: 6 }}>
                                                <Autocomplete
                                                    size="small"
                                                    fullWidth
                                                    options={Fabrikater}
                                                    getOptionLabel={(option) => option.Beskrivelse || ""}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.InstrumentFabrikatId === value.InstrumentFabrikatId
                                                    }
                                                    onChange={(event, value) => {
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "fabrikat",
                                                            value ? value.FabrikatId : ""
                                                        );
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "modell",
                                                            ""
                                                        );
                                                        getmodeller(value ? value.FabrikatId : "");
                                                    }}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Fabrikat"
                                                            name="fabrikat"
                                                            error={
                                                                NyttInstrumentFormik.touched.fabrikat &&
                                                                Boolean(NyttInstrumentFormik.errors.fabrikat)
                                                            }
                                                            helperText={
                                                                NyttInstrumentFormik.touched.fabrikat &&
                                                                NyttInstrumentFormik.errors.fabrikat
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 6 }}>
                                                <Autocomplete
                                                    size="small"
                                                    fullWidth
                                                    options={Modeller}
                                                    getOptionLabel={(option) => option.Beskrivelse || ""}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.ModellId === value.ModellId
                                                    }
                                                    onChange={(event, value) => {
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "modell",
                                                            value ? value.ModellId : ""
                                                        );
                                                    }}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Modell"
                                                            name="modell"
                                                            error={
                                                                NyttInstrumentFormik.touched.modell &&
                                                                Boolean(NyttInstrumentFormik.errors.modell)
                                                            }
                                                            helperText={
                                                                NyttInstrumentFormik.touched.modell &&
                                                                NyttInstrumentFormik.errors.modell
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Button
                                            size="small"
                                            type="submit"
                                            form="nyttinstrumentform"
                                            variant="outlined"
                                            color="success"
                                        >
                                            <SaveIcon className='jvbtnicon' />
                                            {Text({ tid: "sendmelding" })}
                                        </Button>
                                    </Box>

                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div >
            }
        </>
    )
}

export default InnsendingDetaljer;